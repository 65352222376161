<template>
  <div class="container-wrap">
    <NavBar :title="$t('ui_login_pwd')" theme="white" v-if="showTitle"></NavBar>
    <div class="content">
      <div :class="[`flex flex-row`, `flex-center`, `flex-justify-between flex-wrap`]">
        <div
          v-for="(item, index) in filteredList"
          :key="index"
          class="flex flex-column flex-start u_w--100p panel"
        >
          <span class="u_m-l12" v-text="`${$t(item.text)}：`"></span>
          <van-field
            v-model="item.input"
            :placeholder="$t(item.placeholder)"
            :type="showPassword[item.key] ? 'text' : 'password'"
            required
            clearable
            pattern="\w*"
            :right-icon="showPassword[item.key] ? 'closed-eye' : 'eye-o'"
            @click-right-icon="toggleType(item.key)"
            v-on="$root.inputEvents"
          />
        </div>
      </div>
    </div>
    <div class="flex flex-row flex-center u_m-t30 u_m-l12 u_m-r12">
      <van-button
        round
        v-if="+buttonType === 0"
        :class="`main-btn`"
        :disabled="!validated"
        :icon="validated ? 'success' : 'warning'"
        :type="validated ? 'info' : 'default'"
        size="small"
        :hairline="false"
        @click="onSubmit"
        :text="$t('btn_enter')"
      ></van-button>
      <van-button
        round
        v-else-if="+buttonType === 1"
        size="large"
        :hairline="false"
        :class="['main-btn']"
        style="width: calc(100% - .8rem /* 20/25 */)"
        :disabled="!validated"
        :icon="validated ? '' : 'warning'"
        :type="validated ? 'info' : 'default'"
        @click="onSubmit"
        :text="$t('btn_submit')"
      />
    </div>
    <div class="flex flex-row flex-center u_m-t30 u_m-l12 u_m-r12">
      <slot name="hint"></slot>
      <div class="hint text-gold u_fz--small">{{ '※' + validationRules.passwordError }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import {NavBar} from '@/common/wap';
import {Toast, Field, Button} from 'vant';
import {IPasswordResult} from '@/model/password';
import {setPassword} from '@/services/server/wap/password';

@Options({
  components: {
    NavBar,
    vanField: Field,
    vanButton: Button
  },

  props: {
    type: {
      type: [String, Number],
      default: 'updating'
      // 0 or 'setting'
      // 1 or 'updating' (忘記密碼)
    },
    showClose: {
      default: false
    },
    showTitle: {
      default: true
    },
    buttonType: {
      type: [String, Number],
      default: 0
      // 0 确认
      // 1 上一步 提交
      // 2
    },
    secret: {
      type: String,
      default: ''
    }
  },
  methods: {
    onClear() {
      this.inputList.forEach((v: {input: string}) => (v.input = ''));
    },
    async onSubmit() {
      if (!this.validated) {
        return false;
      }
      const ans1 = this.inputList[0].input;
      const ans2 = this.inputList[1].input;
      this.loading = true;
      let data: {
        message?: string;
      } = {};
      if (!this.isSetting) {
        const payload = {
          old_password: ans1,
          new_password: ans2
          // confirm_password: ans3
        };
        this.$emit('submit', payload);
        data = await setPassword.updated<IPasswordResult>(payload);
      }
      this.loading = false;
      // console.log('debug: onSubmit -> data', data);
      let message = '';
      if (data instanceof Error) {
        message = data.message;
      } else {
        message = data.message || '';
      }
      let second = 3;
      const opt = {
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: message
          ? `${message} \n ${this.$t('ui_return_in_seconds', [second])}`
          : this.$t('ui_return_in_seconds', [second])
      };
      const toast = Toast.loading(opt);
      // this.$toast(data.message || (data.data && data.data.message) || '?')
      const timer = setInterval(() => {
        second--;
        if (second) {
          toast.message = message
            ? `${message} \n ${this.$t('ui_return_in_seconds', [second])}`
            : this.$t('ui_return_in_seconds', [second]);
        } else {
          clearInterval(timer);
          // 手动清除 Toast
          Toast.clear();
          this.$nextTick(() => {
            // if (+data.result === 0) {
            this.$emit('updated');
            this.$router.push({name: 'SecurityCenter'});
            //   return;
            // }
          });
        }
      }, 1000);
      this.onClear();
      // this.loading = false
    },
    toggleType(key: string) {
      this.showPassword[key] = !this.showPassword[key];
    },
    onClose(e: boolean) {
      this.$emit('close', e);
    },
    onPrestep(e: boolean) {
      this.$emit('prestep', e);
    }
  },
  computed: {
    isSetting() {
      return this.type === 0 || this.type === 'setting';
    },
    filteredList() {
      if (this.isSetting) {
        return this.inputList.slice(1, 3);
      } else {
        return this.inputList;
      }
    },
    validated() {
      const ans1 = this.inputList[0].input;
      const ans2 = this.inputList[1].input;
      const ans3 = this.inputList[2].input;
      if (/^.{6,12}$/.test(ans2) && ans2 === ans3) {
        if (!this.isSetting) {
          if (/^.{6,12}$/.test(ans1)) {
            return true;
          }
        } else {
          return true;
        }
      }
      if (/^.{6,12}$/.test(ans2) && /^.{6,12}$/.test(ans3)) {
        if (ans2 === ans3 && ans2 && ans3) {
          const msg = this.$t('sysmsg_new_password_limit_1');
          this.$emit('errors', msg);
        }
      }
      return false;
    },
    validationRules() {
      return {
        nameRule: /[\d|a-z|A-Z]{6,12}/,
        passwordRule: /[\d|a-z|A-Z]{6,12}/,
        nameError: this.$t('ui_no_special_symbols_are_allowed', [6, 12]),
        passwordError: this.$t('ui_the_password_must_be_english_letters', [6, 12]),
        emailRule: /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/,
        emailError: this.$t('ui_text_069'),
        lineRule: /(^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$|[A-Za-z.-_]{3,}|\d{7,11})/,
        lineError: this.$t('ui_text_070'),
        qqRule: /(^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$|^[1-9]\d{4,9}$|\d{7,11})/,
        qqError: this.$t('ui_text_071'),
        wechatRule: /(^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$|^[a-zA-Z]{1}[-_a-zA-Z0-9]{5,19}$|\d{7,11})/,
        wechatError: this.$t('ui_text_072'),
        zaloRule: /(\d{7,11})/,
        zaloError: this.$t('ui_text_073'),
        skypeRule: /^[^\u4e00-\u9fa5]+$/,
        skypeError: this.$t('ui_text_074')
      };
    }
  }
})
export default class SetPassword extends Vue {
  showPassword = {
    old: false,
    new: false,
    repeat: false
  };
  loading = false;
  selecting = -1;
  inputList = [
    {
      text: 'ui_input_old_pwd',
      placeholder: 'ui_input',
      input: '',
      key: 'old'
    },
    {
      text: 'ui_input_new_pwd',
      placeholder: 'ui_input',
      input: '',
      key: 'new'
    },
    {
      text: 'ui_input_new_pwd_again',
      placeholder: 'ui_input',
      input: '',
      key: 'repeat'
    }
  ];

  mounted() {
    console.log('mounted');
  }
}
</script>
<style lang="scss" scoped>
.container-wrap {
  background-color: #f6f7f8;
  height: 100%;
  font-size: 0.64rem /* 16/25 */;
  .text {
    line-height: 1.68rem /* 42/25 */;
  }
  .nav-right {
    font-size: 0.56rem;
    color: #333333;
    line-height: 0.56rem;
    margin-right: 0.48rem;
  }
}
.content {
  margin-top: 1.28rem /* 32/25 */;
}
.panel {
  position: relative;
  background-color: $cf;
  * {
    height: 1.68rem /* 42/25 */;
    line-height: 1.68rem;
    @extend .flex-center;
  }
  :deep(.van-cell) {
    width: calc(100% - 0.96rem /* 24/25 */);
    margin: 0 0.48rem /* 12/25 */;
    background-color: #f7f7f7;
    border-radius: 0.24rem /* 6/25 */;
    padding: 0 0.24rem /* 6/25 */;
  }
  :deep(.van-cell--required::before) {
    left: -0.24rem /* 6/25 */;
  }
}
.flex {
  .title,
  .value {
    display: block;
    font-size: $fm;
    font-weight: 400;
  }
  .title {
    color: $c6;
  }
  .value {
    color: $c3;
  }
}
.van-field__button .main-btn {
  height: 1.28rem /* 32/25 */;
}
.main-btn {
  width: 100%;
  margin: auto;
  height: 1.92rem;
  color: $cf;
  background-color: $bg;
  border-radius: 0.32rem /* 8/25 */;
  font-size: 0.64rem;
  border-width: 0;
}
.control-row {
  height: 1.76rem;
  line-height: 1.76rem;
  padding: 0.4rem 0.64rem;
  > * {
    flex: 1;
  }
  p {
    margin: 0 !important;
    @extend .text-align-right;
  }
  :deep(.van-field__control) {
    @extend .text-align-right;
  }
}
.text-gold {
  color: $gold;
}
.u_w--100p {
  width: 100%;
}
</style>
